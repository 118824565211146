import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Slider from "react-slick";

import { ButtonBare } from "../../Button";
import ArticlesCarouselItem from "./ArticlesCarouselItem";

import BlackArrow from "../../../assets/images/svg/icons/arrows/arrow-b.svg";


const ArticlesCarousel = ({
  heading,
  buttonText,
  pageLink
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "news"
              }
              isFeatured: {
                eq: true
              }
            }
          }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                author
                category
                external
                link
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altText              
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const settings = {
    dots: true,
    arrows: true,
    speed: 500,
    infinite: true,
    fadeIn: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        }
      },
    ],
  }

  const articlesCarouselItems = allMarkdownRemark.edges.map(node => {
    if (node.node.frontmatter.external) {
      return (
        <ArticlesCarouselItem
          title={ node.node.frontmatter.title }
          author={ node.node.frontmatter.author }
          category={ node.node.frontmatter.category }
          external={ node.node.frontmatter.external }
          featuredImage={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
          altText={ node.node.frontmatter.altText }
          pageLink={ node.node.frontmatter.link }
        />
      );
    } else {
      return (
        <ArticlesCarouselItem
          title={ node.node.frontmatter.title }
          author={ node.node.frontmatter.author }
          category={ node.node.frontmatter.category }
          external={ node.node.frontmatter.external }
          featuredImage={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
          altText={ node.node.frontmatter.altText }
          pageLink={ node.node.fields.slug }
        />
      );
    }
  });

  return (
    <section className="container mx-auto my-16 px-6 lg:px-28">
      { heading &&
        <div className="flex flex-row justify-between items-center mb-8">
          <h2 className="text-3xl font-thin">{ heading }</h2>
          <Link
            to={ pageLink }
          >
            <BlackArrow className="w-6 lg:hidden" />
          </Link>
          <div className="hidden lg:block">
          <ButtonBare
            buttonText={ buttonText }
            buttonPosition="right"
            pageLink={ pageLink }
          />
          </div>
        </div>
      }
      <div style={{ marginLeft: -1 + 'rem', marginRight: -1 + 'rem' }}>
        <Slider
          { ...settings }
        >
          { articlesCarouselItems }
        </Slider>
      </div>
    </section>
  )
}

export default ArticlesCarousel;