import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";


const ArticlesCarouselItem = ({ 
  title,
  author,
  category,
  external,
  featuredImage,
  altText,
  pageLink
}) => {
  return (
    <div className="mx-4 mb-8 lg:mb-0">
      { external === false &&
        <Link
          to={ pageLink }
        >
          <Img
            fluid={ featuredImage }
            alt={ altText }
          />
        </Link>
      }
      { external === true &&
        <a
          href={ pageLink }
          target="_blank"
          rel="noopener noreferrer"
        >
          <Img
            fluid={ featuredImage }
            alt={ altText }
          />
        </a>
      }
      <p className="pt-4 text-sm font-thin">{ category } &mdash; { author }</p>
      <h3 className="pt-2 text-2xl font-thin">
        <span>{ title }</span>
      </h3>
    </div>
  )
}

ArticlesCarouselItem.propTypes = {
  title: PropTypes.string,
  author: PropTypes.string,
  featuredImage: PropTypes.object,
  altText: PropTypes.string,
  pageLink: PropTypes.string,
}

ArticlesCarouselItem.defaultProps = {
  title: '',
  author: '',
  featuredImage: {},
  altText: '',
  pageLink: '', 
}

export default ArticlesCarouselItem;