import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import ArticlesCarousel from "../components/Carousel/Articles/ArticlesCarousel";
import CallToAction from "../components/CallToAction/CallToAction";
import CasesCarousel from "../components/Carousel/Cases/CasesCarousel";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import IndexHero from "../components/Hero/IndexHero";
import Layout from "../components/Layout/Layout";
import PracticeAreasGrid from "../components/Grid/PracticeAreas/PracticeAreasGrid";
import SingleImage from "../components/SingleMedia/SingleImage";
import TextBlock from "../components/TextBlock/TextBlock";


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/KND-Home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="Class Matters™"
      description="KND Complex Litigation is a boutique Toronto law firm dedicated to protecting the 
      interests of investors and consumers. Our mission is to advocate for and foster fairness, 
      efficiency and integrity in consumer and capital markets in Canada."
    >
      <Header
        colorScheme="dark"
      />

      <IndexHero />

      <SingleImage
        image={ data.file.childImageSharp.fluid }
        imageAlignment="right"
        altText="Strategic chess playing related to law"
        colorScheme="dark"
      />

      <TextBlock 
        text="KND Complex Litigation is a boutique Toronto law firm dedicated to protecting the
        interests of investors and consumers. Our lawyers have extensive experience with class
        actions and other complex litigation across Canada in the areas of securities, privacy,
        consumer and employment law, and have a track-record of success in obtaining
        favourable results for our clients."
        buttonText="Learn more about us"
        pageLink="/about"
      />

      <CasesCarousel
        heading="Featured Class Actions"
        buttonText="View all class actions"
        pageLink="/class-actions"
      />

      <PracticeAreasGrid
        heading="Our areas of focus"
        buttonText="Learn more"
        buttonPosition="left"
      />

      <ArticlesCarousel
        heading="In The News"
        buttonText="View all news"
        pageLink="/news"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default IndexPage;