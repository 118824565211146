import React from "react";
import PropTypes from "prop-types";

import { ButtonBare } from "../../Button";


const PracticeAreasColumn = ({ 
  title,
  shortDescription,
  contrast,
  buttonText,
  buttonPosition,
  pageLink
}) => {
  return (
    <div className="flex flex-col justify-between h-64 lg:h-80 xl:h-72 2xl:h-64 py-8 border-t border-white">
      <h3 className="text-2xl">
        <span>{ title }</span>
      </h3>
      <p className="text-gray-400 font-thin">{ shortDescription }</p>
      <ButtonBare
        buttonText={ buttonText }
        buttonPosition={ buttonPosition }
        contrast={ contrast }
        pageLink={ pageLink }
      />
    </div>
  )
}

PracticeAreasColumn.propTypes = {
  title: PropTypes.string,
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  buttonPosition: PropTypes.string,
  pageLink: PropTypes.string,
}

PracticeAreasColumn.defaultProps = {
  title: '',
  buttonType: '',
  buttonText: '',
  buttonPosition: '',
  pageLink: '', 
}

export default PracticeAreasColumn;