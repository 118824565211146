import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PracticeAreasColumn from "./PracticeAreasColumn";


const PracticeAreasGrid = ({
  heading,
  buttonType,
  buttonText,
  buttonPosition
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "practice-area"
              }
            }
          }
          sort: {
            fields: [frontmatter___order]
            order: ASC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                shortDescription               
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const practiceAreasColumns = allMarkdownRemark.edges.map(node => {
    return (
      <PracticeAreasColumn 
        title={ node.node.frontmatter.title }
        shortDescription={ node.node.frontmatter.shortDescription }
        contrast={ true }
        buttonType={ buttonType }
        buttonText={ buttonText }
        buttonPosition={ buttonPosition }
        pageLink={ node.node.fields.slug }
      />
    )
  });
  
  return (
    <section className="relative w-full mt-0 mb-0 lg:my-24 text-white">
      <div className="w-full lg:w-11/12 h-full absolute bottom-0 bg-blue-dark z-n10"></div>
      <div className="py-16 practice-areas-grid">
        <h2 className="mb-8 text-3xl font-thin">{ heading }</h2>
        <div className="lg:grid lg:grid-cols-3 lg:gap-x-16">
          { practiceAreasColumns }
        </div>
      </div>
    </section>
  )
}

export default PracticeAreasGrid;