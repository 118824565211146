import React, { useState } from "react";

import Socials from "../Navigation/Socials";
import VideoModal from "../SingleMedia/VideoModal";

import PlayVideoWhite from "../../assets/images/svg/icons/video/play-video-w.svg";


const VideoButton = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const openVideoModal = () => {
    setShowVideoModal(prev => !prev);
  };

  return (
    <>
      <button
        className="flex flex-row items-center"
        onClick={ openVideoModal }
      >
        <span className="flex-inital">
          <PlayVideoWhite className="w-6" />
        </span>
        <span className="flex-inital pl-2 font-thin">Video coming soon</span>
      </button>
      <VideoModal
        video=""
        videoTitle="KND Complex Litigation"
        showVideoModal={ showVideoModal }
        setShowVideoModal={ setShowVideoModal }
      />
    </>
  )
}


const IndexHero = () => {

  return (
    <section className="w-screen">
      <div className="pt-28 lg:pt-32 bg-blue-dark text-white">
        <div className="container mx-auto px-6 pt-4 lg:pt-24 pb-8 lg:pb-0 lg:pl-28 lg:pr-6 xl:pl-80">
          <h1 className="text-3xl md:text-4xl font-bold font-custom tracking-widest">
            <span className="block">CLASS</span>
            <span className="flex flex-row items-top"><span className="flex-initial pr-2">MATTERS</span><span className="flex-initial text-base md:text-lg">TM</span></span>
          </h1>
          <p className="py-8 lg:w-4/5 2xl:w-3/5 font-thin lg:text-lg">
            At KND Complex Litigation, our mission is to advocate for and foster fairness, efficiency and 
            integrity in consumer and capital markets in Canada.
          </p>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center pt-8 2xl:pt-24 tracking-wide">
            <div className="hidden lg:block">
              <Socials />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndexHero;